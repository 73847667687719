import axios from 'axios'
import qs from 'qs'
import {baseApi} from '@/config' // 根据环境不同引入不同api地址
import {decrypt, encrypt, handleEncryptedData} from '../utils/crypto'
import router from '@/router'
import {getToken} from "@/utils/auth";
import {Message} from 'element-ui'
import store from "@/store";

const service = axios.create({
  baseURL: baseApi, // url = base api url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
  transformRequest: data => qs.stringify(data)
});

service.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

//设置请求拦截器
//客户端发送请求=》请求拦截器=》服务器
// TOKEN校验（JWT）接收服务器返回的token
// 存储到vuex/本地存储中，每一次发请求我们应该吧token带上

service.interceptors.request.use((
  config) => {
  /*if (process.env.VUE_APP_ENV === 'production') {
    // 加密
    const {url, params, data} = handleEncryptedData(config, 'encrypt');
    if (config.method === 'GET' || config.method === 'DELETE') {
      config.url = url;
      config.params = params
    } else {
      config.data = {
        data
      };
    }
  }*/

  // 携带上token
  let token = getToken();
  token && (config.headers.Authorization = token);
  if(!config.data){
    config.data = {
      time:new Date().getTime()
    }
  }else{
    config.data.time = new Date().getTime();
  }
  const {data} = handleEncryptedData(config, 'encrypt');
  config.data.sign = data;
  return config
}, error => {
  return Promise.reject(error)
});

//响应拦截器
//服务器返回信息=》拦截的统一处理=》客户端js获取到信息
// axios.defaults.validatestatus=status=>{
//自定义响应成功的http状态码
//     return /^(2|3)\d{2}$/.test(status)
// }
service.interceptors.response.use(
  response => {
    let res = response.data;
    if (response?.status !== 200) {
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      if (res.code === 1) {
        return Promise.resolve(res)
      } else {
        if (res.code === 401) {
          setTimeout(() => {
            router.replace('/login').catch(err => err)
          }, 1000);
          store.dispatch('user/resetToken').then(() => {
          });
          Message.error(res.msg)
          return Promise.reject(new Error(res.msg));
        } else if (res.code === 404) {
          router.replace('/404').then(() => {
          });
          return Promise.reject(new Error('404'));
        } else if (res.code === 403) {
          router.replace('/403').then(() => {
          });
          return Promise.reject(new Error('403'));
        } else if (res.code === 0) {
          return Promise.reject(new Error(res.msg));
        }
      }
    }

  },
  error => {
    if (error.response?.status === 500) {
      Message({
        message: '500: 服务器错误', //error.response.data.message
        type: 'error',
        duration: 5 * 1000
      });
    }

    return Promise.reject(error)

  });

export default service
