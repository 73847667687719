// 正式
module.exports = {
  // old
  // title: '天音视通——平台管理系统',
  // baseUrl: 'https://syp.tianyinvod.com', // 正式项目地址
  // baseApi: 'https://syy.tianyinvod.com', // 正式api请求地址

  // new ytsaas.com
  title: '天音视通——平台管理系统',
  baseUrl: 'https://syp.ytsaas.com', // 正式项目地址
  baseApi: 'https://yty.ytsaas.com', // 正式api请求地址
};
